<template>
  <div>
    <div class="gradualChange">
      <div>
        <h2>电子签章工具-单机版</h2>
        <p>
          电子签章工具快速帮您完成电子文件签署，并保证电子文件的法律有效性和实现电子文件的不可篡改。与Ukey（类似于银行U盾，电子印章的载体）配合使用，插入Ukey数字证书即可完成签署流程。
        </p>
        <router-link :to="{ name: 'product-instructions' }" class="purchaseUkey">购买Ukey</router-link>
      </div>
    </div>
    <div class="UkeyCharacteristic clearfix">
      <h2>即插即用，安全便捷的签章工具</h2>
      <h5>有数字证书就能签，告别繁琐注册开通步骤</h5>
      <div class="demonstrate">
        <img src="@/assets/image/productCenter/signatures/demonstrate.png" alt="签署示例" />
      </div>
      <div class="UkeyIntroduce">
        <dl v-for="(item, index) in demonstrate" :key="index" @click="demonstrateActive = index" :class="{ active: index == demonstrateActive }">
          <dt>
            <img :src=" require(`@/assets/image/productCenter/signatures/introduce${index + 1}.png`)" alt="签章icon" />{{ item.name }}
          </dt>
          <dd>{{ item.txt }}</dd>
        </dl>
      </div>
    </div>
    <div class="UkeySuperiority">
      <h2>我们的优势</h2>
      <h5>安全、合规、便捷的电子签章产品</h5>
      <div class="advantageStatement clearfix">
        <dl v-for="(item, index) in product" :key="index">
          <dt>{{ item.name }}</dt>
          <dd v-for="(txt, i) in item.superiority" :key="i">
            {{ txt }}
          </dd>
        </dl>
      </div>
    </div>
    <div class="propaganda">
      <h2>豸信电子签章工具，使签署更简单更高效</h2>
      <router-link :to="{ name: 'product-instructions' }" class="buyNow">立即购买</router-link>
    </div>
    <div class="customerStories">
      <h2>客户案例</h2>
      <div class="client">
        <div class="carousel-item" v-for="(item, index) in client" :key="index">
          <img :src="require(`@/assets/icons/home/client/client-logo${item}.png`)" alt="客户案例" />
        </div>
      </div>
    </div>
    <LearnMore />
  </div>
</template>

<script>
import LearnMore from './components/LearnMore.vue'
export default {
  components: { LearnMore },
  data () {
    return {
      demonstrate: [
        {
          name: '多种签署方式',
          txt:
            '支持单页签、多页签、骑缝签等多种签章方式，符合用户物理用章习惯',
        },
        {
          name: '丰富的签署场景',
          txt:
            '满足公文签章、合同签章、标书盖章等用户多种电子文件的盖章诉求',
        },
        {
          name: '支持超大文件',
          txt:
            '能够快速实现对超过1G的超大文件进行电子盖章，无需打印、快递合同，盖得越多省得越多',
        },
        {
          name: '兼容多签章标准',
          txt:
            '签章标准符合《安全电子签章密码技术规范》以及兼容ISO 32000国际通用标准',
        },
        {
          name: '离线签署',
          txt:
            '实现无网络进行签章，有效解决离线环境下的盖章诉求，确保业务有序进行',
        },
      ],
      demonstrateActive: 0,
      product: [
        {
          name: '安全稳定',
          superiority: [
            '数字证书、密钥、印章数据加密保存在Ukey中',
            'Ukey+PIN码双因子身份认证，有效防止非授权的使用',
            'Pin码基于物理芯片，具有自动锁死机制',
          ],
        },
        {
          name: '合法合规',
          superiority: [
            '硬件密码模块通过国家密码管理局的检测认证',
            'Ukey内置SM2数字证书并由豸信CA直接颁发',
            '签章标准符合GB 38540规范，利于互通',
          ],
        },
        {
          name: '灵活应用',
          superiority: [
            '无需对接业务系统，无需附加设备，插入Ukeyt直接使用',
            '体积小，重量轻，方便随身携带',
          ],
        },
        {
          name: '功能丰富',
          superiority: [
            '具备单页签章、多页签章、签骑缝章、验证等功能',
            '能够满足多种业务场景的签章需求',
          ],
        },
      ],
      client: [4, 3, 6, 7, 10, 13, 14, 9, 11, 15],
    }
  },
}
</script>

<style lang="scss" scoped>
.gradualChange {
  width: 100%;
  height: 564px;
  background: url('~@/assets/image/productCenter/signatures/gradualChange.png')
    center center no-repeat;
  background-size: 100% 564px;
  > div {
    width: 1300px;
    margin: 0 auto;
  }
  h2 {
    font-size: 40px;
    color: #303b50;
    line-height: 53px;
    padding-top: 206px;
    margin-bottom: 36px;
  }
  p {
    width: 683px;
    font-size: 16px;
    color: #303b50;
    line-height: 36px;
    letter-spacing: 1px;
  }
}
.purchaseUkey {
  margin-top: 32px;
  display: inline-block;
  width: 109px;
  height: 42px;
  background: #1c67f3;
  border-radius: 4px;
  font-size: 16px;
  color: #ffffff;
  line-height: 42px;
  text-align: center;
}
.UkeyCharacteristic {
  width: 1300px;
  margin: 0 auto;
  padding-top: 156px;
  padding-bottom: 160px;
  h2 {
    font-size: 40px;
    color: #303b50;
    line-height: 53px;
    text-align: center;
    margin-bottom: 19px;
  }
  h5 {
    font-size: 21px;
    color: #9ba4b0;
    line-height: 27px;
    text-align: center;
    margin-bottom: 100px;
    font-weight: normal;
  }
}
.demonstrate {
  width: 638px;
  height: 530px;
  float: left;
  img {
    width: 100%;
  }
}
.UkeyIntroduce {
  float: right;
  dl {
    width: 551px;
    border-radius: 8px;
    background: #f0f4f9;
    padding: 20px 30px;
    margin-bottom: 24px;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .active {
    background: #e8f0ff;
  }
  dt {
    font-size: 21px;
    color: #303b50;
    line-height: 32px;
    cursor: pointer;
  }
  img {
    width: 34px;
    margin-right: 16px;
  }
  dd {
    display: none;
    cursor: pointer;
  }
  .active dd {
    display: block;
    padding-top: 14px;
    padding-left: 50px;
    font-size: 16px;
    color: #303b50;
    line-height: 28px;
  }
}
.UkeySuperiority {
  background: #f9fbff;
  padding-bottom: 178px;
  h2 {
    text-align: center;
    font-size: 40px;
    color: #303b50;
    line-height: 53px;
    padding-top: 94px;
    margin-bottom: 16px;
  }
  h5 {
    text-align: center;
    font-size: 21px;
    color: #9ba4b0;
    line-height: 27px;
    margin-bottom: 100px;
    font-weight: normal;
  }
}
.advantageStatement {
  width: 1300px;
  margin: 0 auto;
  dl {
    width: 630px;
    height: 332px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    box-shadow: 0px 8px 24px 0px rgba(133, 167, 222, 0.29);
    border-radius: 8px;
    border: 1px solid #ffffff;
    backdrop-filter: blur(7px);
    padding: 47px 40px;
    &:nth-child(2n) {
      float: right;
    }
    &:nth-child(2n + 1) {
      float: left;
    }
    &:nth-child(2),
    &:first-child {
      margin-bottom: 40px;
    }
  }
  dt {
    font-size: 32px;
    color: #1c67f3;
    line-height: 43px;
    margin-bottom: 60px;
    font-weight: 700;
  }
  dd {
    font-size: 18px;
    color: #303b50;
    line-height: 24px;
    margin-bottom: 18px;
    padding-left: 16px;
    position: relative;
    &::before {
      content: '';
      width: 6px;
      height: 6px;
      background: #303b50;
      border-radius: 3px;
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -3px;
    }
  }
}
.propaganda {
  height: 354px;
  background: url('~@/assets/image/productCenter/signatures/propaganda.png')
    center center no-repeat;
  background-size: 100% 100%;
  text-align: center;
  h2 {
    font-size: 36px;
    color: #ffffff;
    line-height: 47px;
    padding: 98px 0;
  }
  .buyNow {
    display: inline-block;
    width: 110px;
    height: 42px;
    background: #1c67f3;
    border-radius: 4px;
    font-size: 16px;
    color: #ffffff;
    line-height: 42px;
  }
}
.customerStories {
  width: 1300px;
  margin: 196px auto 0;
  h2 {
    font-size: 40px;
    color: #303b50;
    line-height: 53px;
    letter-spacing: 1px;
    text-align: center;
  }
}
.client {
  margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
}
.carousel-item {
  width: 241px;
  height: 144px;
  background: #fdfdff;
  box-shadow: 0px 2px 8px 0px rgba(31, 37, 81, 0.11);
  border-radius: 4px;
  margin: 0 23px 24px 0;
  text-align: center;
  flex-shrink: 0;
  position: relative;
  &:nth-child(5n) {
    margin-right: 0;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
